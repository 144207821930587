import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  filterColumn: null,
  filterValue: null,
};

export const FilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {

    setFilterColumn: (state, action ) => {
      console.log('setFilterColumn', action.payload);
      return { ...state, filterColumn: action.payload };
    },

    setFilterValue: (state, action ) => {
      console.log('setFilterValue', action.payload);
      return { ...state, filterValue: action.payload };
    },

    resetFilter: (state, action) => {
      console.log('RESET FILTER');
      return {...state, filterColumn: null, filterValue: null };
    },
  },
});

export const {
  setFilterColumn,
  setFilterValue,
  resetFilter,
} = FilterSlice.actions;
