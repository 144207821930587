import {AuthAPI} from "./api/AuthAPI";
import {UserAPI} from "./api/UserAPI";
import {ProfileAPI} from "./api/ProfileAPI";
import {SportAPI} from "./api/SportAPI";
import {ResolutionAPI} from "./api/ResolutionAPI";
import {ServerAPI} from "./api/ServerAPI";
import {StreamAPI} from "./api/StreamAPI";
import {StatusAPI} from "./api/StatusAPI";

export const API = {
  auth: AuthAPI,
  profile: ProfileAPI,
  user: UserAPI,
  sport: SportAPI,
  resolution: ResolutionAPI,
  server: ServerAPI,
  stream: StreamAPI,
  status: StatusAPI,
};

export default API;
