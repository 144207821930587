import {lazy} from "react";
import {APP_PREFIX_PATH} from "../AppConfig";
import RouteAccess from "constants/RouteAccess";

export const OthersRoutes = [
  /* Broadcast */
  {
    key:       'streams',
    path:      `${APP_PREFIX_PATH}/broadcast/streams`,
    component: lazy(()=>import('views/Broadcast/Streams')),
    access:    RouteAccess.PRIVATE,
  },
  {
    key:       'archive',
    path:      `${APP_PREFIX_PATH}/broadcast/archive`,
    component: lazy(()=>import('views/Broadcast/Streams')),
    access:    RouteAccess.PRIVATE,
  },
  {
    key:       'monitoring',
    path:      `${APP_PREFIX_PATH}/broadcast/monitoring`,
    component: lazy(()=>import('views/Broadcast/Monitoring')),
    access:    RouteAccess.PRIVATE,
  },
  // {
  //   key:       'profile',
  //   path:      `${APP_PREFIX_PATH}/profile`,
  //   component: lazy(()=>import('views/Profile')),
  //   access:    RouteAccess.PRIVATE,
  // },

  /* References */
  {
    key:       'servers',
    path:      `${APP_PREFIX_PATH}/references/servers`,
    component: lazy(()=>import('views/References/Servers')),
    access:    RouteAccess.PRIVATE,
  },
  {
    key:       'sports',
    path:      `${APP_PREFIX_PATH}/references/sports`,
    component: lazy(()=>import('views/References/Sports')),
    access:    RouteAccess.PRIVATE,
  },
  {
    key:       'references',
    path:      `${APP_PREFIX_PATH}/references/resolutions`,
    component: lazy(()=>import('views/References/Resolutions')),
    access:    RouteAccess.PRIVATE,
  },

  /* System */
  {
    key:       'users',
    path:      `${APP_PREFIX_PATH}/system/users`,
    component: lazy(()=>import('views/Users')),
    access:    RouteAccess.PRIVATE,
  },
  {
    key:       'help',
    path:      `${APP_PREFIX_PATH}/system/help`,
    component: lazy(()=>import('views/System/Help')),
    access:    RouteAccess.PRIVATE,
  },
  {
    key:       'settings',
    path:      `${APP_PREFIX_PATH}/system/settings`,
    component: lazy(()=>import('views/System/Settings')),
    access:    RouteAccess.PRIVATE,
  },
];
