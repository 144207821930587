import {lazy} from "react";
import {AUTH_PREFIX_PATH} from "../AppConfig";
import RouteAccess from "constants/RouteAccess";

export const AuthRoutes = [
  {
    /****************************************************
     * Default root path component !!!
     ***************************************************/
    key:       'root',
    path:      `/`,
    component: lazy(()=>import('views/Auth/Login')),
    access:    RouteAccess.PUBLIC,
  },
  {
    key:       'register',
    path:      `${AUTH_PREFIX_PATH}/register`,
    component: lazy(()=>import('views/Auth/Register')),
    access:    RouteAccess.PUBLIC,
  },
  {
    key:       'login',
    path:      `${AUTH_PREFIX_PATH}/login`,
    component: lazy(()=>import('views/Auth/Login')),
    access:    RouteAccess.PUBLIC,
  },
  {
    key:       'logout',
    path:      `${AUTH_PREFIX_PATH}/logout`,
    component: lazy(()=>import('views/Auth/Logout')),
    access:    RouteAccess.PRIVATE,
  },
  {
    key:       'recovery',
    path:      `${AUTH_PREFIX_PATH}/recovery`,
    component: lazy(()=>import('views/Auth/Recovery')),
    access:    RouteAccess.PUBLIC,
  },
  {
    key:       'confirm',
    path:      `${AUTH_PREFIX_PATH}/confirm`,
    component: lazy(()=>import('views/Auth/Confirm')),
    access:    RouteAccess.PUBLIC,
  },
  {
    key:       'reset',
    path:      `${AUTH_PREFIX_PATH}/reset`,
    component: lazy(()=>import('views/Auth/Reset')),
    access:    RouteAccess.PUBLIC,
  }
];
